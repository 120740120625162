"use client";

import ErrorMessage from "./error";
export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html lang={"no"}>
      <body>
        <ErrorMessage error={error} reset={reset} />
      </body>
    </html>
  );
}
